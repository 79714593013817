<template>
  <v-data-table
  :headers="headers"
  :items="items"
  :disable-sort="disableSort"
  :disable-pagination="disablePagination"
  :items-per-page="itemsPerPage"
  :loading="loading"
  :disabled="disabled"
  :height="height"
  :fixed-header="fixedHeader"
  :item-class="itemClass"
  :hide-default-footer="hideDefaultFooter"
  :loading-text="loadingText"/>
</template>
<script>
export default {
  props: {
    headers: Array,
    items: Array,
    disableSort: Boolean,
    disablePagination: Boolean,
    itemsPerPage: Number,
    loading: Boolean,
    disabled: Boolean,
    height: Number,
    fixedHeader: Boolean,
    itemClass: Function,
    hideDefaultFooter: Boolean,
    loadingText: String
  }
}
</script>
<style>

</style>